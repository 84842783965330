import { format } from 'date-fns';
import * as Yup from 'yup';

import { UserType } from '@/config/types';
import { useFms } from '@/hooks/useFms';
import { fmsWithTemplate } from '@/i18n/fmsWithTemplate';
import { useUserDateFnsLocale } from '@/i18n/hooks';
import { CurrencyCode } from '@/types/CurrencyCode';
import { isMessageValid } from '@/util/isMessageValid';

import { WithdrawalOrderFormValues } from '../../types/WithdrawalOrderFormValues';
import { expectedTransferDate } from '../../utils/expectedTransferDate';
import { cosignerFieldValidationSchema } from '../rules/cosignerFieldValidationSchema';
import { portfolioNameFieldValidationSchema } from '../rules/portfolioNameFieldValidationSchema';
import { signatoryOptionValidationSchema } from '../rules/signatoryOptionValidationSchema';
import { signerFieldValidationSchema } from '../rules/signerFieldValidationSchema';
import { signingProviderFieldValidationSchema } from '../rules/signingProviderFieldValidationSchema';

interface ValidationSchema {
  userType: UserType;
}

export const useCreateWithdrawalValidationSchema = ({
  userType,
}: ValidationSchema): Yup.SchemaOf<WithdrawalOrderFormValues> => {
  const texts = useFms();
  const errorTexts = texts.orders.form.errors;
  const dateFnsLocale = useUserDateFnsLocale();

  return Yup.object({
    portfolioId: portfolioNameFieldValidationSchema({
      portfolioRequired: errorTexts.portfolioRequired,
    }),

    fromPortfolioBankAccount: Yup.string().required(
      errorTexts.portfolioBankAccountRequired,
    ),

    externalBankAccount: Yup.string().required(
      errorTexts.withdrawal.externalBankAccount,
    ),

    amount: Yup.number()
      .required(errorTexts.amountRequired)
      .min(1, errorTexts.amountRequired),

    currency: Yup.mixed()
      .oneOf(Object.values(CurrencyCode))
      .required(errorTexts.currencyRequired),

    expectedTransferDate: Yup.date()
      .required(texts.orders.form.errors.dateRequired)
      .typeError(
        // Type error is when date parsing fails due to
        // the input value not being parsable by `new Date()`.
        fmsWithTemplate(texts.orders.form.errors.dateFormat, {
          format: expectedTransferDate.dateFormat(dateFnsLocale),
          example: format(
            expectedTransferDate.minimumDate(),
            expectedTransferDate.dateFormat(dateFnsLocale),
          ),
        }),
      )
      .min(
        expectedTransferDate.minimumDate(),
        fmsWithTemplate(texts.orders.form.errors.dateInPast, {
          date: format(
            expectedTransferDate.minimumDate(),
            expectedTransferDate.dateFormat(dateFnsLocale),
          ),
        }),
      ),

    messageToSam: Yup.string()
      .optional()
      .max(
        500,
        fmsWithTemplate(errorTexts.maxMessageLength, {
          max: '500',
        }),
      )
      .test(
        'is-message-valid',
        errorTexts.invalidCharactersInMessage,
        (value, context) => {
          const validationResult = isMessageValid(value || '');

          if (validationResult.isValid) {
            // All good!
            // The message is valid.
            return true;
          }

          // The message is invalid.
          // Return an error with the invalid characters.
          return context.createError({
            message: fmsWithTemplate(errorTexts.invalidCharactersInMessage, {
              characters: validationResult.invalidCharacters.join(' '),
            }),
          });
        },
      ),

    signatoryOption: signatoryOptionValidationSchema({
      signatoryOptionRequired: errorTexts.signatoryOptionRequired,
    }),

    signer: signerFieldValidationSchema({
      signerRequired: errorTexts.signerRequired,
    }),

    cosigner: cosignerFieldValidationSchema({
      cosignerRequired: errorTexts.cosignerRequired,
    }),

    signingProvider: signingProviderFieldValidationSchema(userType, {
      signingProviderRequired: errorTexts.signingProviderRequired,
    }),
  });
};
