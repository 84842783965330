import Alert from '@storeblocks/alert';
import Button from '@storeblocks/button';
import Input from '@storeblocks/input';
import { Form as FormikForm, Formik } from 'formik';
import React from 'react';

import {
  Source,
  useContactUsFormMutation,
} from '@/api/contactUsForm/contactUsFormApi';
import { useUserSettings } from '@/api/userSettings/utils/useUserSettings';
import { useCurrentUserInfo } from '@/auth/hooks';
import { Column, Row } from '@/components/Grid';
import { H3, Hint } from '@/components/Typography';
import { WithGap } from '@/components/WithGap';
import { UserType } from '@/config/types';
import { useFms } from '@/hooks/useFms';
import { TrackingElement, useGetTrackId } from '@/tracking';

import { FormValidationSummary } from '../Form/FormValidationSummary';
import { formHasErrors } from '../Form/utils/formHasErrors';
import { contactFormValidation } from './contactFormValidation';
import { ContactFormValues } from './contactFormValues';
import { getDescriptionFromSource } from './getDescriptionFromSource';

interface Props {
  source: Source;
  onClose: () => void;
  onSuccessfulSubmit: () => void;
}

/**
 * The actual form for the contact us form.
 */
export const Form: React.FC<Props> = ({
  source,
  onClose,
  onSuccessfulSubmit,
}) => {
  const texts = useFms();
  const getTrackId = useGetTrackId();
  const { emailBusiness } = useUserSettings();
  const { userType } = useCurrentUserInfo();
  const isInternal = userType === UserType.Internal;

  const [postForm, request] = useContactUsFormMutation();

  const description = getDescriptionFromSource(source, texts);

  const initialValues: ContactFormValues = {
    email: emailBusiness,
    phoneNumber: '',
    message: '',
  };

  const handleSubmit = async (values: ContactFormValues): Promise<void> => {
    try {
      await postForm({
        source,
        email: values.email,
        phoneNumber: values.phoneNumber,
        message: values.message,
      }).unwrap();

      // Notify that the form has been successfully submitted.
      onSuccessfulSubmit();
    } catch (error) {
      // Do nothing.
      // The error will be displayed in the form.
      return Promise.resolve();
    }
  };

  return (
    <WithGap gap="16">
      <H3>{texts.pages.contactUs.header.title}</H3>

      <div>{description}</div>

      <Formik
        initialValues={initialValues}
        validationSchema={contactFormValidation(texts)}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting, submitCount, errors, touched, getFieldProps }) => (
          <FormikForm data-test="contact-us-form">
            <WithGap gap="16">
              <Input
                id="message"
                label={texts.pages.contactUs.form.message.title}
                type="text"
                error={touched['message'] && errors['message']}
                {...getFieldProps('message')}
                as="textarea"
                rows={3}
                disabled={isInternal}
                fluid
              />

              <Row>
                <Column $span={7}>
                  <Input
                    id="email"
                    label={texts.pages.contactUs.form.email.title}
                    error={touched['email'] && errors['email']}
                    {...getFieldProps('email')}
                    type="email"
                    disabled={isInternal}
                    fluid
                  />
                </Column>

                <Column $span={5}>
                  <Input
                    id="phoneNumber"
                    label={texts.pages.contactUs.form.phone.title}
                    error={touched['phoneNumber'] && errors['phoneNumber']}
                    {...getFieldProps('phoneNumber')}
                    type="tel"
                    disabled={isInternal}
                    fluid
                  />
                </Column>
              </Row>

              {/* Validation error summary */}
              {submitCount > 0 && formHasErrors(errors) && (
                <FormValidationSummary
                  title={texts.pages.contactUs.form.validationSummary.title}
                  errors={errors}
                />
              )}

              {/* POST request error */}
              {request.isError && (
                <Alert
                  data-test="contact-us-form-error"
                  variant="error"
                  title={texts.error.header.unexpected}
                  description={texts.error.description.unexpected}
                />
              )}

              <WithGap gap="8" direction="row">
                {/* Submit button */}
                <Button
                  type="submit"
                  disabled={isSubmitting || isInternal}
                  data-trackid={getTrackId(
                    'submit-contact-us-form',
                    TrackingElement.Button,
                  )}
                >
                  {texts.pages.contactUs.form.send.title}
                </Button>

                {/* Cancel button */}
                <Button
                  type="button"
                  variant="outlined"
                  onClick={() => onClose()}
                  data-test="cancel-contact-us-form"
                  data-trackid={getTrackId(
                    'cancel-contact-us-form',
                    TrackingElement.Button,
                  )}
                >
                  {texts.pages.contactUs.cancel}
                </Button>
              </WithGap>
              {isInternal && (
                <Hint>{`* ${texts.pages.contactUs.internalDisabled}`}</Hint>
              )}
            </WithGap>
          </FormikForm>
        )}
      </Formik>
    </WithGap>
  );
};
