import {
  PendingOrderDto,
  PendingOrdersReply,
} from '../dtos/PendingOrdersReply';
import { OrderType } from '../models/OrderType';
import { PendingOrder } from '../models/PendingOrder';
import { mapOrderWithSignUrlDtoToModel } from './mapOrderWithSignUrlDtoToModel';

export const transformPendingOrdersReply = (
  reply: PendingOrdersReply,
): Array<PendingOrder> => {
  return reply.pendingOrders.map(mapPendingOrderDtoToModel);
};

const mapPendingOrderDtoToModel = (dto: PendingOrderDto): PendingOrder => {
  const { order, signUrl } = mapOrderWithSignUrlDtoToModel(dto);

  let portfolioNumber = '';

  switch (order.orderType) {
    case OrderType.Subscription:
    case OrderType.Redemption:
    case OrderType.Switch:
    case OrderType.Deposit:
    case OrderType.Withdrawal:
      portfolioNumber = order.portfolioNumber;
      break;

    case OrderType.Transfer:
      portfolioNumber = order.fromPortfolioId;
      break;
  }

  return {
    orderId: order.id,
    orderType: order.orderType,
    signUrl,
    createdBy: order.initiatorName,
    createdDate: order.createdDate,
    portfolioNumber,
  };
};
