import round from 'lodash/round';
import React, { FC } from 'react';
import styled from 'styled-components';

import { Show } from '@/components/Show';
import { TextShort } from '@/components/Typography';
import { useUserLocale } from '@/i18n/hooks';

import { GaugeColor } from '../types/GaugeColor';

interface Props {
  value: number;
  max?: number;
  color?: GaugeColor;
  decimals?: number;
  minimize?: boolean;
}

/**
 * The Linear Gauge is used to show a visual representation of a ranged number in a linear (horizontal) format.
 * @param {number} value - The value to be rendered.
 * @param {number} [max=10] - The maximum number of the range.
 * @param {GaugeColor} [color] - Customize the colors of the gauge.
 * @param {number} [decimals=2] - Number of decimal points to round the value to.
 * @param {boolean} minimize - Render as a miniature version.
 */
export const LinearGauge: FC<Props> = ({
  value,
  max = 10,
  color,
  decimals = 2,
  minimize = false,
}) => {
  const locale = useUserLocale();

  if (value > max) {
    throw new Error(
      `The value [${value}] cannot be higher than the defined max (${max}).`,
    );
  }

  // The gauge integer value used to compare with bar index.
  const valueWithoutDecimals = round(value, 0);
  // Round the displayed gauge value to the provided number of decimal points.
  const displayValue = round(value, decimals);

  return (
    <Container total={max} minimize={minimize}>
      {[...new Array(max).keys()].map((barIndex) => {
        // Check if the barIndex is equal to the gauge value.
        const isValue = valueWithoutDecimals === barIndex + 1;

        // The bar is marked as active if the index is less than the gauge value.
        const isActive = barIndex < valueWithoutDecimals;

        // Display a value below the bar for the first and last bar as well as the bar matching the current gauge value.
        const isValueVisible =
          barIndex === 0 || isValue || barIndex === max - 1;

        return (
          <BarContainer
            key={`bar_${barIndex}`}
            index={barIndex}
            minimize={minimize}
          >
            <Bar isActive={isActive} barColor={color} />
            <Show when={!minimize}>
              <Value isVisible={isValueVisible}>
                {isValue ? displayValue.toLocaleString(locale) : barIndex + 1}
              </Value>
            </Show>
          </BarContainer>
        );
      })}
    </Container>
  );
};

interface ContainerProps {
  total: number;
  minimize?: boolean;
}

const Container = styled.div<ContainerProps>`
  display: grid;
  height: ${({ minimize }) => (minimize ? '32px' : '92px')};
  grid-template-columns: repeat(
    ${({ total }) => total},
    ${({ minimize }) => (minimize ? '16px' : '24px')}
  );
  gap: ${({ minimize }) => (minimize ? '8px' : '24px')};
`;

interface BarContainerProps {
  index: number;
  minimize?: boolean;
}

const BarContainer = styled.div<BarContainerProps>`
  width: ${({ minimize }) => (minimize ? '16px' : '24px')};
  display: flex;
  flex-direction: column;
  justify-content: center;
  opacity: 0;
  animation-name: set-bar-color;
  animation-duration: 0.5s;
  animation-iteration-count: 1;
  animation-delay: ${({ index }) => index * 0.05}s;
  animation-fill-mode: forwards;
  @keyframes set-bar-color {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;

interface BarProps {
  isActive: boolean;
  barColor?: GaugeColor;
}

const Bar = styled.div<BarProps>`
  flex: 1;
  border-radius: 5px;
  background-color: ${({ isActive, barColor }) =>
    isActive
      ? (barColor?.color ?? 'var(--color-blue-50)')
      : (barColor?.background ?? 'var(--color-blue-20)')};

  // Display the background color in print
  -webkit-print-color-adjust: exact;
  color-adjust: exact;
`;

interface ValueProps {
  isVisible: boolean;
}

const Value = styled(TextShort)<ValueProps>`
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  line-height: 40px;
  text-align: center;
`;
