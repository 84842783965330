import React, {
  FC,
  PropsWithChildren,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useNavigate } from 'react-router';

import { useFundListQuery } from '@/api/funds/fundsApi';
import {
  isDepositOrder,
  isFundOrder,
  isTransferOrder,
  isWithdrawalOrder,
} from '@/api/order/models/Order';
import { OrderType } from '@/api/order/models/OrderType';
import { useOrganizationCmId } from '@/api/userSettings/hooks/useOrganizationCmId';
import { Pending } from '@/components/Pending';
import { Show } from '@/components/Show';
import { useConsumeQueryParameter } from '@/util/useConsumeQueryParameter';

import { useExistingOrder } from './hooks/useExistingOrder';
import { useFetchRequiredFormData } from './hooks/useFetchRequiredFormData';
import {
  DepositOrder,
  FundOrder,
  TransferOrder,
  WithdrawalOrder,
} from './types/Order';
import { getFundOrderFromOrder } from './utils/getFundOrderFromOrder';

type Order = FundOrder | DepositOrder | WithdrawalOrder | TransferOrder;

interface OrderPageState<O extends Order> {
  orderType: OrderType;
  currentOrder: O | undefined;
  setCurrentOrder: (order: O | undefined) => void;
  /** Order is already existing, only needs signing. */
  orderForSigning: { id: string; signUrl: string } | undefined;
  signingCancelled: boolean;
  hasExistingOrder: boolean;
  cancel: () => void;
  reset: () => void;
}

const OrderPageContext = React.createContext<OrderPageState<Order> | null>(
  null,
);

interface Props extends PropsWithChildren {
  orderType: OrderType;
}

export const OrderPageProvider: FC<Props> = ({ orderType, children }) => {
  const [currentOrder, setCurrentOrder] = useState<Order | undefined>();

  const signingCancelled = useConsumeQueryParameter('signingCancelled');

  const { data, isFetching } = useExistingOrder();

  const organizationCmId = useOrganizationCmId();

  const navigate = useNavigate();

  const hasRequiredData = useFetchRequiredFormData();
  const { data: fundList } = useFundListQuery();

  const reset = (): void => {
    setCurrentOrder(undefined);
  };

  const cancel = (): void => {
    navigate(`/${organizationCmId}`);
  };

  useEffect(() => {
    reset();
  }, [orderType]);

  // Set the appropriate order if there is an existing order.
  useEffect(() => {
    if (!data || !fundList) {
      return;
    }

    // The order that was fetched from the backend.
    const { order } = data;

    // The backend order that is transformed into an order for the frontend form.
    let formOrder: Order;

    if (isFundOrder(order)) {
      formOrder = getFundOrderFromOrder(order, fundList);
    } else if (isDepositOrder(order)) {
      formOrder = {
        orderType: OrderType.Deposit,
        userId: order.initiatorId,
        portfolioId: order.portfolioNumber,
        signatories: order.signatories,
        signingProvider: order.signingProvider,

        amount: order.amount,
        currency: order.currency,
        messageToSam: order.messageToSam,
        toPortfolioBankAccountNumber: order.toPortfolioBankAccountNumber,
        expectedDepositDate: order.expectedDepositDate,
      };
    } else if (isWithdrawalOrder(order)) {
      formOrder = {
        orderType: OrderType.Withdrawal,
        userId: order.initiatorId,
        portfolioId: order.portfolioNumber,
        signatories: order.signatories,
        signingProvider: order.signingProvider,

        amount: order.amount,
        currency: order.currency,
        messageToSam: order.messageToSam,
        fromPortfolioBankAccountNumber: order.fromPortfolioBankAccountNumber,
        externalBankAccount: order.externalBankAccount,
        expectedTransferDate: order.expectedTransferDate,
      };
    } else if (isTransferOrder(order)) {
      formOrder = {
        orderType: OrderType.Transfer,
        userId: order.initiatorId,
        signatories: order.signatories,
        signingProvider: order.signingProvider,
        fromPortfolioId: order.fromPortfolioId,
        fromPortfolioBankAccountNumber: order.fromPortfolioBankAccountNumber,
        fromPortfolioBankAccountCurrency:
          order.fromPortfolioBankAccountCurrency,
        toPortfolioId: order.toPortfolioId,
        toPortfolioBankAccountNumber: order.toPortfolioBankAccountNumber,
        toPortfolioBankAccountCurrency: order.toPortfolioBankAccountCurrency,
        amount: order.amount,
        messageToSam: order.messageToSam,
        expectedTransferDate: order.expectedTransferDate,
      };
    }

    setCurrentOrder(formOrder);
  }, [data, fundList]);

  const contextData = useMemo<OrderPageState<Order>>(() => {
    return {
      orderType,
      currentOrder,
      setCurrentOrder,
      orderForSigning: data && {
        id: data.order.id,
        signUrl: data.signUrl,
      },
      signingCancelled: Boolean(signingCancelled),
      hasExistingOrder: !!data,
      cancel,
      reset,
    };
  }, [orderType, currentOrder, data, signingCancelled]);

  return (
    <>
      <Show when={isFetching || !hasRequiredData}>
        <Pending center />
      </Show>
      <Show when={hasRequiredData} animate>
        <OrderPageContext.Provider value={contextData}>
          {children}
        </OrderPageContext.Provider>
      </Show>
    </>
  );
};

export function useOrderPageContext<O extends Order>(): OrderPageState<O> {
  const context = useContext(OrderPageContext);

  if (!context) {
    throw new Error(
      `${useOrderPageContext.name} must be called within a ${OrderPageProvider.name}.`,
    );
  }

  return context as unknown as OrderPageState<O>;
}
