import { useEffect, useRef, useState } from 'react';

export const useConsumeQueryParameter = (name: string): string | undefined => {
  const nameRef = useRef(name);
  const [param] = useState<string | undefined>(() => {
    const url = new URL(window.location.href);
    const queryParam = url.searchParams.get(name);
    return queryParam ?? undefined;
  });

  useEffect(() => {
    const url = new URL(window.location.href);
    const queryParam = url.searchParams.get(nameRef.current);

    if (queryParam !== null) {
      // Remove the query parameter from the URL.
      url.searchParams.delete(nameRef.current);
      window.history.replaceState({}, '', url);
    }
  }, []);

  return param;
};
