import Input from '@storeblocks/input';
import Select, { Option, SelectChangeEvent } from '@storeblocks/select';
import { useFormikContext } from 'formik';
import React, { FC } from 'react';

import { FormValidationSummary } from '@/components/Form/FormValidationSummary';
import { formHasErrors } from '@/components/Form/utils/formHasErrors';
import { Column, Row } from '@/components/Grid';
import { H3 } from '@/components/Typography/H3';
import { WithGap } from '@/components/WithGap';
import { useFms } from '@/hooks/useFms';
import { nameof } from '@/util/nameof';

import { useDiscretionaryPortfolios } from '../../hooks/useDiscretionaryPortfolios';
import { TransferOrderFormValues } from '../../types/TransferOrderFormValues';
import { NavigationButtons } from '../NavigationButtons';
import { SignatoryOptions } from '../SignatoryOptions';
import { SinglePortfolioBankAccount } from '../SinglePortfolioBankAccount';
import { ExpectedTransferDateSelector } from './ExpectedTransferDateSelector';
import { TransferAmountInput } from './TransferAmountInput';
import {
  TransferDirection,
  TransferPortfolioBankAccountsSelect,
} from './TransferPortfolioBankAccountsSelect';

export const TransferOrderFormContent: FC = () => {
  const texts = useFms();

  const { values, errors, touched, submitCount, getFieldProps, setFieldValue } =
    useFormikContext<TransferOrderFormValues>();

  const portfolios = useDiscretionaryPortfolios();
  const optionsPortfolio = portfolios.map<Option>((portfolio) => ({
    value: portfolio.id,
    label: `${portfolio.id} - ${portfolio.name}`,
  }));

  const [selectedFromPortfolio] = portfolios.filter(
    (portfolio) => portfolio.id === values.fromPortfolioId,
  );

  const [selectedToPortfolio] = portfolios.filter(
    (portfolio) => portfolio.id === values.toPortfolioId,
  );

  const handleFromPortfolioChanged = async (
    event: SelectChangeEvent,
  ): Promise<void> => {
    const { onChange } = getFieldProps(
      nameof<TransferOrderFormValues>('fromPortfolioId'),
    );

    onChange(event);

    const [portfolio] = portfolios.filter(
      (portfolio) => portfolio.id === event.target.value,
    );

    if (portfolio.portfolioBankAccounts.length === 1) {
      const [portfolioBankAccount] = portfolio.portfolioBankAccounts;

      await setFieldValue(
        nameof<TransferOrderFormValues>('fromPortfolioBankAccountNumber'),
        portfolioBankAccount.accountNumber,
      );

      await setFieldValue(
        nameof<TransferOrderFormValues>('fromCurrency'),
        portfolioBankAccount.currency,
      );
    }
  };

  const handleToPortfolioChanged = async (
    event: SelectChangeEvent,
  ): Promise<void> => {
    const { onChange } = getFieldProps(
      nameof<TransferOrderFormValues>('toPortfolioId'),
    );

    onChange(event);

    const [portfolio] = portfolios.filter(
      (portfolio) => portfolio.id === event.target.value,
    );

    if (portfolio.portfolioBankAccounts.length === 1) {
      const [portfolioBankAccount] = portfolio.portfolioBankAccounts;

      await setFieldValue(
        nameof<TransferOrderFormValues>('toPortfolioBankAccountNumber'),
        portfolioBankAccount.accountNumber,
      );

      await setFieldValue(
        nameof<TransferOrderFormValues>('toCurrency'),
        portfolioBankAccount.currency,
      );
    }
  };

  return (
    <WithGap gap="64">
      <WithGap gap="32">
        <H3>{texts.orders.form.header.transfer.from}</H3>

        <Row>
          <Column $span={6}>
            <Select
              {...getFieldProps(
                nameof<TransferOrderFormValues>('fromPortfolioId'),
              )}
              id="from-portfolio-selector"
              label={texts.orders.form.fromPortfolioSelect.transfer.title}
              hint={texts.orders.form.portfolio.message}
              placeholder={texts.orders.form.portfolio.placeholder}
              error={touched.fromPortfolioId && errors.fromPortfolioId}
              options={optionsPortfolio}
              fluid
              onChange={handleFromPortfolioChanged}
            />
          </Column>
        </Row>

        {values.fromPortfolioId && (
          <Row>
            <Column $span={6}>
              {selectedFromPortfolio.portfolioBankAccounts.length > 1 ? (
                <TransferPortfolioBankAccountsSelect
                  portfolioId={selectedFromPortfolio.id}
                  transferDirection={TransferDirection.From}
                />
              ) : (
                <SinglePortfolioBankAccount
                  portfolioBankAccountNumber={
                    selectedFromPortfolio.portfolioBankAccounts[0].accountNumber
                  }
                />
              )}
            </Column>
          </Row>
        )}
      </WithGap>

      <WithGap gap="32">
        <H3>{texts.orders.form.header.transfer.to}</H3>
        <Row>
          <Column $span={6}>
            <Select
              {...getFieldProps(
                nameof<TransferOrderFormValues>('toPortfolioId'),
              )}
              id="to-portfolio-selector"
              label={texts.orders.form.toPortfolioSelect.transfer.title}
              hint={texts.orders.form.portfolio.message}
              placeholder={texts.orders.form.portfolio.placeholder}
              error={touched.toPortfolioId && errors.toPortfolioId}
              options={optionsPortfolio}
              fluid
              onChange={handleToPortfolioChanged}
            />
          </Column>
        </Row>

        {values.toPortfolioId && (
          <Row>
            <Column $span={6}>
              {selectedToPortfolio.portfolioBankAccounts.length > 1 ? (
                <TransferPortfolioBankAccountsSelect
                  portfolioId={selectedToPortfolio.id}
                  transferDirection={TransferDirection.To}
                />
              ) : (
                <SinglePortfolioBankAccount
                  portfolioBankAccountNumber={
                    selectedToPortfolio.portfolioBankAccounts[0].accountNumber
                  }
                />
              )}
            </Column>
          </Row>
        )}
      </WithGap>

      <WithGap gap="32">
        <Row>
          <Column $span={6}>
            <TransferAmountInput />
          </Column>
        </Row>

        <Row>
          <Column $span={6}>
            <ExpectedTransferDateSelector />
          </Column>
        </Row>

        <Row>
          <Column $span={6}>
            <Input
              {...getFieldProps(
                nameof<TransferOrderFormValues>('messageToSam'),
              )}
              as="textarea"
              id="message-to-sam"
              label={texts.orders.form.messageToSam.title}
              hint={texts.orders.form.messageToSam.description.transfer}
              error={touched.messageToSam && errors.messageToSam}
              type="text"
              fluid
            />
          </Column>
        </Row>

        <SignatoryOptions />

        {submitCount > 0 && formHasErrors(errors) && (
          <FormValidationSummary
            title={texts.orders.form.errors.validationSummary.title}
            errors={errors}
          />
        )}

        {values.toPortfolioId && <NavigationButtons />}
      </WithGap>
    </WithGap>
  );
};
