import { PostDepositOrderDto } from '@/api/order/dtos/PostDepositOrderDto';
import { PostTransferOrderDto } from '@/api/order/dtos/PostTransferOrderDto';
import { PostWithdrawalOrderDto } from '@/api/order/dtos/PostWithdrawalOrderDto';
import { OrderType } from '@/api/order/models/OrderType';
import { mapPostDepositOrderModelToDepositDto } from '@/api/order/utils/mapPostDepositOrderModelToDepositDto';
import { mapPostTransferOrderModelToTransferDto } from '@/api/order/utils/mapPostTransferOrderModelToTransferDto';
import { mapPostWithdrawalOrderModelToWithdrawalDto } from '@/api/order/utils/mapPostWithdrawalOrderModelToWithdrawalDto';
import { Language } from '@/i18n/Language';
import {
  DepositOrder,
  TransferOrder,
  WithdrawalOrder,
} from '@/pages/Order/types/Order';

export const mapPostDiscretionaryOrderModelToDto = (
  order: DepositOrder | WithdrawalOrder | TransferOrder,
  language: Language,
): PostDepositOrderDto | PostWithdrawalOrderDto | PostTransferOrderDto => {
  switch (order.orderType) {
    case OrderType.Deposit:
      return mapPostDepositOrderModelToDepositDto(order, language);

    case OrderType.Withdrawal:
      return mapPostWithdrawalOrderModelToWithdrawalDto(order, language);

    case OrderType.Transfer:
      return mapPostTransferOrderModelToTransferDto(order, language);
  }
};
