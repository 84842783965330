import { parseISO } from 'date-fns';

import { CurrencyCode } from '@/types/CurrencyCode';
import { convertStringToEnum } from '@/util/convertStringToEnum';

import { OrderDto } from '../dtos/OrderDto';
import { OrderPlacementDto } from '../dtos/OrderPlacementDto';
import {
  DepositOrder,
  FundOrder,
  TransferOrder,
  WithdrawalOrder,
} from '../models/Order';
import { OrderPaymentType } from '../models/OrderPaymentType';
import { OrderPlacement } from '../models/OrderPlacement';
import { OrderPlacementType } from '../models/OrderPlacementType';
import { OrderType } from '../models/OrderType';
import { OrderValueType } from '../models/OrderValueType';
import { mapOrderTypeDtoToEnum } from './mapOrderTypeDtoToEnum';

export function mapOrderDtoToModel(
  order: OrderDto,
): FundOrder | DepositOrder | WithdrawalOrder | TransferOrder {
  const orderType = mapOrderTypeDtoToEnum(order.orderType);

  if (
    orderType === OrderType.Subscription ||
    orderType === OrderType.Redemption ||
    orderType === OrderType.Switch
  ) {
    const fundOrder: FundOrder = {
      orderType,
      id: order.id,
      createdDate: parseISO(order.created),
      initiatorId: order.userId,
      initiatorName: order.userName,
      portfolioNumber: order.portfolioNumber,
      orderPlacements:
        order.orderPlacements?.map(mapOrderPlacementDtoToModel) ?? [],
      signatories: order.signatories,
    };

    return fundOrder;
  }

  if (orderType === OrderType.Deposit) {
    const deposit: DepositOrder = {
      orderType: OrderType.Deposit,
      id: order.id,
      createdDate: parseISO(order.created),
      initiatorId: order.userId,
      initiatorName: order.userName,
      portfolioNumber: order.portfolioNumber,
      toPortfolioBankAccountNumber: order.toPortfolioBankAccountNumber,
      amount: order.amount,
      currency: convertStringToEnum(order.currencyCode, CurrencyCode),
      expectedDepositDate: parseISO(order.expectedDepositDate),
      messageToSam: order.specifications ?? null,
      signatories: order.signatories,
    };

    return deposit;
  }

  if (orderType === OrderType.Withdrawal) {
    const withdrawal: WithdrawalOrder = {
      orderType: OrderType.Withdrawal,
      id: order.id,
      createdDate: parseISO(order.created),
      initiatorId: order.userId,
      initiatorName: order.userName,
      portfolioNumber: order.portfolioNumber,
      fromPortfolioBankAccountNumber: order.fromPortfolioBankAccountNumber,
      externalBankAccount: order.toExternalBankAccountNumber,
      amount: order.amount,
      currency: convertStringToEnum(order.currencyCode, CurrencyCode),
      expectedTransferDate: parseISO(order.expectedTransferDate),
      messageToSam: order.specifications ?? null,
      signatories: order.signatories,
    };

    return withdrawal;
  }

  if (orderType === OrderType.Transfer) {
    const transfer: TransferOrder = {
      orderType: OrderType.Transfer,
      id: order.id,
      createdDate: parseISO(order.created),
      initiatorId: order.userId,
      initiatorName: order.userName,
      fromPortfolioId: order.fromPortfolioId,
      fromPortfolioBankAccountNumber: order.fromPortfolioBankAccountNumber,
      fromPortfolioBankAccountCurrency: convertStringToEnum(
        order.currencyCode,
        CurrencyCode,
      ),
      toPortfolioId: order.toPortfolioId,
      toPortfolioBankAccountNumber: order.toPortfolioBankAccountNumber,
      toPortfolioBankAccountCurrency: convertStringToEnum(
        order.currencyCode,
        CurrencyCode,
      ),
      amount: order.amount,
      messageToSam: order.specifications ?? null,

      expectedTransferDate: parseISO(order.expectedTransferDate),
      signatories: order.signatories,
    };

    return transfer;
  }

  throw new Error(
    `Could not map order DTO to model. Unknown order type [${orderType}].`,
  );
}

const mapOrderPlacementDtoToModel = (
  dto: OrderPlacementDto,
): OrderPlacement => {
  return {
    fundIsin: dto.isin,
    hash: dto.hash,
    valueType: convertStringToEnum(dto.valueType, OrderValueType),
    accountId: dto.accountId,
    // Set default currency value to `NOK` for old orders missing currency.
    currency: convertStringToEnum(dto.currency || 'NOK', CurrencyCode),
    externalAccountNumber: dto.externalAccountNumber,
    fundName: dto.fundName,
    id: dto.id,
    isin: dto.isin,
    payment: convertStringToEnum(dto.payment, OrderPaymentType),
    placementType: convertStringToEnum(dto.placementType, OrderPlacementType),
    value: dto.value,
  };
};
