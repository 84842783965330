import { formatISO } from 'date-fns';

import { Language } from '@/i18n/Language';
import { WithdrawalOrder } from '@/pages/Order/types/Order';

import { PostWithdrawalOrderDto } from '../dtos/PostWithdrawalOrderDto';

export const mapPostWithdrawalOrderModelToWithdrawalDto = (
  withdrawalOrder: WithdrawalOrder,
  language: Language,
): PostWithdrawalOrderDto => {
  return {
    order: {
      portfolioId: withdrawalOrder.portfolioId,
      signingProviderId: withdrawalOrder.signingProvider,
      signatories: withdrawalOrder.signatories,
      amount: withdrawalOrder.amount,
      currency: withdrawalOrder.currency,
      message: withdrawalOrder.messageToSam?.trim() || null,
      expectedTransferDate: formatISO(withdrawalOrder.expectedTransferDate, {
        representation: 'date',
      }),
      fromPortfolioBankAccountNumber:
        withdrawalOrder.fromPortfolioBankAccountNumber,
      toExternalBankAccountNumber: withdrawalOrder.externalBankAccount,
    },
    language,
  };
};
