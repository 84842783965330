import Alert from '@storeblocks/alert';
import { Form, Formik, FormikHelpers } from 'formik';
import React from 'react';
import { useSearchParams } from 'react-router-dom';

import { OrderType } from '@/api/order/models/OrderType';
import { OrderWithSignUrl } from '@/api/order/models/OrderWithSignUrl';
import { usePostOrderMutation } from '@/api/order/orderApi';
import { useOrderListQuery } from '@/api/orderList/orderListApi';
import { Permission } from '@/api/permissions/models/Permission';
import { useUserSettings } from '@/api/userSettings/utils/useUserSettings';
import { useCurrentUserInfo } from '@/auth/hooks';
import { ContactForm } from '@/components/ContactForm/ContactForm';
import { Column, Row } from '@/components/Grid';
import { useHasPermission } from '@/components/Permissions/useHasPermission';
import { H2 } from '@/components/Typography';
import { WithGap } from '@/components/WithGap';
import { isCypress } from '@/config/utils';
import { useFms } from '@/hooks/useFms';

import { useOrderSteps } from '../../components/OrderStepsProvider';
import { useContactFormSource } from '../../hooks/useContactFormSource';
import { useIsCurrentUserOwner } from '../../hooks/useIsCurrentUserOwner';
import { useOrderPageContext } from '../../OrderPageContext';
import {
  DepositOrder,
  TransferOrder,
  WithdrawalOrder,
} from '../../types/Order';
import { ConfirmInformationIsCorrectCheckbox } from './components/ConfirmInformationIsCorrectCheckbox';
import { DepositOrderDetailsTable } from './components/DepositOrderDetailsTable';
import { NavigationButtons } from './components/NavigationButtons';
import { PostFailedAlert } from './components/PostFailedAlert';
import { TransferOrderDetailsTable } from './components/TransferOrderDetailsTable';
import { WithdrawalOrderDetailsTable } from './components/WithdrawalOrderDetailsTable';
import { ConfirmFormValues } from './types/ConfirmFormValues';
import { mapPostDiscretionaryOrderModelToDto } from './utils/mapDiscretionaryOrderToDto';
import { validationSchema } from './utils/validationSchema';

const postError = 'post-error';

const initialFormValues: ConfirmFormValues = {
  confirmInformationIsCorrect: false,
};

export const DiscretionaryOrderConfirm: React.FC = () => {
  const texts = useFms();

  const [, setSearchParams] = useSearchParams();
  const contactFormSource = useContactFormSource();

  const { completeCurrentStep } = useOrderSteps();

  const userInfo = useCurrentUserInfo();
  const hasPermission = useHasPermission();
  const isOwner = useIsCurrentUserOwner();

  const { language } = useUserSettings();

  const {
    orderType,
    currentOrder,
    orderForSigning,
    signingCancelled,
    hasExistingOrder,
  } = useOrderPageContext<DepositOrder | WithdrawalOrder | TransferOrder>();

  const orderListQuery = useOrderListQuery();
  const [postOrder] = usePostOrderMutation();

  const handleSubmit = async (
    _: ConfirmFormValues,
    formikHelpers: FormikHelpers<ConfirmFormValues>,
  ): Promise<void> => {
    try {
      let createdOrder: OrderWithSignUrl;

      if (!orderForSigning) {
        const dto = mapPostDiscretionaryOrderModelToDto(currentOrder, language);

        createdOrder = await postOrder({ order: dto, orderType }).unwrap();
      }

      // Set the orderId in the url to allow the user to click the back button to edit their order.
      setSearchParams(
        {
          orderId: orderForSigning
            ? orderForSigning.id.toString()
            : createdOrder.order.id.toString(),
        },
        { replace: true },
      );

      if (isCypress()) {
        // Don't redirect to signing when we are on Cypress tests due to
        // window.location.href redirects the Cypress Chrome instance and not
        // the page under test.

        completeCurrentStep();
        return;
      }

      // Redirect to Signicat for signing if the user has order sign permission
      // and the current user is one of the signatories.
      if (
        hasPermission(Permission.OrdersSign) &&
        currentOrder.signatories.includes(userInfo.userCmId)
      ) {
        window.location.href = orderForSigning
          ? orderForSigning.signUrl
          : createdOrder.signUrl;
        return;
      }

      // Refetch the order list to show the newly created order.
      orderListQuery.refetch();

      // Do not redirect and complete the current step if the user does not have order sign permission
      // or if the current user is not one of the signatories but complete the current step to immediately
      // show the completed step instead.
      completeCurrentStep();
    } catch (error) {
      formikHelpers.setStatus(postError);
    }
  };
  return (
    <WithGap gap="48">
      <WithGap>
        <Row>
          <Column $span={9}>
            <ContactForm source={contactFormSource} />
          </Column>
        </Row>
        <H2>{texts.orders.form.steps.confirm.title}</H2>
      </WithGap>

      {orderType == OrderType.Deposit && <DepositOrderDetailsTable />}

      {orderType == OrderType.Withdrawal && <WithdrawalOrderDetailsTable />}

      {orderType == OrderType.Transfer && <TransferOrderDetailsTable />}

      <Formik
        initialValues={initialFormValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema(texts)}
      >
        {({ status, isSubmitting }) => (
          <Form id="discretionary-confirmation-form">
            <WithGap gap="48">
              <WithGap gap="24">
                <ConfirmInformationIsCorrectCheckbox orderType={orderType} />
                {status === postError && <PostFailedAlert />}
              </WithGap>

              {signingCancelled && (
                <Alert
                  data-test="signing-cancelled-alert"
                  variant="info"
                  title={texts.orders.form.steps.confirm.signingCancelledTitle}
                  description={
                    texts.orders.form.steps.confirm.signingCancelledDescription
                  }
                />
              )}

              <NavigationButtons
                isPending={isSubmitting}
                canGoBack={isOwner && !hasExistingOrder}
              />
            </WithGap>
          </Form>
        )}
      </Formik>
    </WithGap>
  );
};
