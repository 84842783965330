import { CurrencyCode } from '@/types/CurrencyCode';

import { OrderPlacement } from './OrderPlacement';
import { OrderType } from './OrderType';
import { SigningProvider } from './SigningProvider';

/**
 * @deprecated Use `FundOrder`, `DepositOrder`, `WithdrawalOrder`, or `TransferOrder` instead.
 */
export interface Order {
  /**
   * ID of the order.
   *
   * This will either be a number or a UUID.
   * Legacy orders use numbers, while new orders use UUIDs.
   */
  id: string;
  createdDate: Date;

  /**
   * Portfolio short name.
   * @example 'P1227F'
   */
  portfolioNumber: string;

  orderType: OrderType;

  /**
   * The user who created the order.
   *
   * - For external users this will be a CMID.
   * - For internal users this will be their Storebrand signature.
   */
  initiatorId: string;
  initiatorName: string;

  signatories: Array<string>;
  signingProvider?: SigningProvider;

  /**
   * Fund order
   */
  orderPlacements?: Array<OrderPlacement>;

  /**
   * Discretionary order
   */
  amount?: number;
  currency?: CurrencyCode;
  messageToSam?: string;

  /**
   * Discretionary deposit orders
   */
  toPortfolioBankAccountNumber?: string;
  expectedDepositDate?: Date;

  /**
   * Discretionary withdrawal orders
   */
  fromPortfolioBankAccountNumber?: string;
  externalBankAccount?: string;
  expectedTransferDate?: Date;
}

export interface FundOrder {
  orderType: OrderType.Subscription | OrderType.Redemption | OrderType.Switch;

  /**
   * ID of the order.
   *
   * This will either be a number or a UUID.
   * Legacy orders use numbers, while new orders use UUIDs.
   */
  id: string;
  createdDate: Date;

  /**
   * Portfolio short name.
   * @example 'P1227F'
   */
  portfolioNumber: string;

  /**
   * The user who created the order.
   *
   * - For external users this will be a CMID.
   * - For internal users this will be their Storebrand signature.
   */
  initiatorId: string;
  initiatorName: string;

  orderPlacements: Array<OrderPlacement>;

  signatories: Array<string>;
  signingProvider?: SigningProvider;
}

export interface DepositOrder {
  orderType: OrderType.Deposit;

  /**
   * ID of the order.
   *
   * This will either be a number or a UUID.
   * Legacy orders use numbers, while new orders use UUIDs.
   */
  id: string;
  createdDate: Date;

  /**
   * Portfolio short name.
   * @example 'P1227F'
   */
  portfolioNumber: string;

  /**
   * The user who created the order.
   *
   * - For external users this will be a CMID.
   * - For internal users this will be their Storebrand signature.
   */
  initiatorId: string;
  initiatorName: string;

  amount: number;
  currency: CurrencyCode;
  messageToSam: string | null;
  toPortfolioBankAccountNumber: string;
  expectedDepositDate: Date;

  signatories: Array<string>;
  signingProvider?: SigningProvider;
}

export interface WithdrawalOrder {
  orderType: OrderType.Withdrawal;

  /**
   * ID of the order.
   *
   * This will either be a number or a UUID.
   * Legacy orders use numbers, while new orders use UUIDs.
   */
  id: string;
  createdDate: Date;

  /**
   * Portfolio short name.
   * @example 'P1227F'
   */
  portfolioNumber: string;

  /**
   * The user who created the order.
   *
   * - For external users this will be a CMID.
   * - For internal users this will be their Storebrand signature.
   */
  initiatorId: string;
  initiatorName: string;

  amount: number;
  currency: CurrencyCode;
  messageToSam: string | null;
  fromPortfolioBankAccountNumber: string;
  externalBankAccount: string;
  expectedTransferDate: Date;

  signatories: Array<string>;
  signingProvider?: SigningProvider;
}

export interface TransferOrder {
  orderType: OrderType.Transfer;

  /**
   * ID of the order.
   *
   * This will either be a number or a UUID.
   * Legacy orders use numbers, while new orders use UUIDs.
   */
  id: string;
  createdDate: Date;

  /**
   * The user who created the order.
   *
   * - For external users this will be a CMID.
   * - For internal users this will be their Storebrand signature.
   */
  initiatorId: string;
  initiatorName: string;

  toPortfolioId: string;
  toPortfolioBankAccountNumber: string;
  toPortfolioBankAccountCurrency: CurrencyCode;
  fromPortfolioId: string;
  fromPortfolioBankAccountNumber: string;
  fromPortfolioBankAccountCurrency: CurrencyCode;
  amount: number;
  messageToSam: string | null;
  expectedTransferDate: Date;

  signatories: Array<string>;
  signingProvider?: SigningProvider;
}

/**
 * Internal type guard for orders.
 * Not to be exported. Use the specific type guards instead.
 */
const isOrder = (order: unknown): boolean =>
  typeof order === 'object' && order !== null && 'orderType' in order;

/**
 * Type guard for fund orders.
 */
export const isFundOrder = (order: unknown): order is FundOrder =>
  isOrder(order) &&
  ((order as FundOrder).orderType === OrderType.Subscription ||
    (order as FundOrder).orderType === OrderType.Redemption ||
    (order as FundOrder).orderType === OrderType.Switch);

/**
 * Type guard for deposit orders.
 */
export const isDepositOrder = (order: unknown): order is DepositOrder =>
  isOrder(order) && (order as DepositOrder).orderType === OrderType.Deposit;

/**
 * Type guard for withdrawal orders.
 */
export const isWithdrawalOrder = (order: unknown): order is WithdrawalOrder =>
  isOrder(order) &&
  (order as WithdrawalOrder).orderType === OrderType.Withdrawal;

/**
 * Type guard for transfer orders.
 */
export const isTransferOrder = (order: unknown): order is TransferOrder =>
  isOrder(order) && (order as TransferOrder).orderType === OrderType.Transfer;
