import Alert from '@storeblocks/alert';
import Button from '@storeblocks/button';
import React from 'react';

import { useOrganizationCmId } from '@/api/userSettings/hooks/useOrganizationCmId';
import { InternalLink } from '@/components/InternalLink';
import { H2 } from '@/components/Typography';
import { WithGap } from '@/components/WithGap';
import { useFms } from '@/hooks/useFms';
import { TrackingElement, useGetTrackId } from '@/tracking';

interface Props {
  isOwner: boolean;
  hasCosigners: boolean;
  newOrderOnClick?: () => void;
}

export const FundOrderCompleted: React.FC<Props> = ({
  isOwner,
  hasCosigners,
  newOrderOnClick,
}) => {
  const texts = useFms();
  const organizationCmId = useOrganizationCmId();
  const getTrackId = useGetTrackId();

  const alertTitle =
    isOwner && hasCosigners
      ? texts.orders.form.orderReceived.sentToCosigner
      : texts.orders.form.orderReceived.received;

  return (
    <WithGap gap="48">
      <H2 data-test="completed-step-header">
        {texts.orders.form.steps.finished.title}
      </H2>

      <Alert title={alertTitle} variant="success">
        <>
          {texts.orders.form.orderReceived.message}
          {/* Have a space between the message and the link text. */}{' '}
          <InternalLink
            to={`/${organizationCmId}/trading/orders`}
            data-trackid={getTrackId('go-to-order-list', TrackingElement.Link)}
          >
            {texts.orders.form.orderReceived.linkText}
          </InternalLink>
        </>
      </Alert>

      <WithGap gap="8" direction="row">
        <Button
          variant="outlined"
          type="button"
          to={`/${organizationCmId}/trading/subscription`}
          as={InternalLink}
          onClick={newOrderOnClick}
          data-test="new-subscription-order"
          data-trackid={getTrackId('go-to-subscription', TrackingElement.Link)}
        >
          {texts.orders.form.buttons.buy}
        </Button>

        <Button
          variant="outlined"
          type="button"
          to={`/${organizationCmId}/trading/redemption`}
          as={InternalLink}
          onClick={newOrderOnClick}
          data-test="new-redemption-order"
          data-trackid={getTrackId('go-to-redemption', TrackingElement.Link)}
        >
          {texts.orders.form.buttons.sell}
        </Button>

        <Button
          variant="outlined"
          type="button"
          to={`/${organizationCmId}/trading/switch`}
          as={InternalLink}
          onClick={newOrderOnClick}
          data-test="new-switch-order"
          data-trackid={getTrackId('go-to-switch', TrackingElement.Link)}
        >
          {texts.orders.form.buttons.switch}
        </Button>

        <Button
          variant="text"
          iconRight="arrow-right"
          type="button"
          to={`/${organizationCmId}`}
          as={InternalLink}
          data-trackid={getTrackId('go-to-overview', TrackingElement.Link)}
        >
          {texts.orders.form.buttons.overview}
        </Button>
      </WithGap>
    </WithGap>
  );
};
