import React, { useEffect } from 'react';
import { useNavigate } from 'react-router';

import { OrderType } from '@/api/order/models/OrderType';
import { useOrganizationCmId } from '@/api/userSettings/hooks/useOrganizationCmId';
import { Pending } from '@/components/Pending';

import { useExistingOrder } from './hooks/useExistingOrder';

/**
 * The page the user is redirected to when they complete the signing of an order.
 * This component will redirect to the correct trading page based on the order type.
 */
export const EssSigningCompletedPage: React.FC = () => {
  const organizationCmId = useOrganizationCmId();
  const { data } = useExistingOrder();
  const navigate = useNavigate();

  useEffect(() => {
    if (!data) {
      return;
    }

    switch (data.order.orderType) {
      case OrderType.Subscription:
        navigate(
          `/${organizationCmId}/trading/subscription/signed?orderId=${data.order.id}`,
          {
            replace: true,
          },
        );
        break;

      case OrderType.Redemption:
        navigate(
          `/${organizationCmId}/trading/redemption/signed?orderId=${data.order.id}`,
          {
            replace: true,
          },
        );
        break;

      case OrderType.Switch:
        navigate(
          `/${organizationCmId}/trading/switch/signed?orderId=${data.order.id}`,
          {
            replace: true,
          },
        );
        break;

      case OrderType.Deposit:
        navigate(
          `/${organizationCmId}/discretionary/deposit/signed?orderId=${data.order.id}`,
          {
            replace: true,
          },
        );
        break;

      case OrderType.Withdrawal:
        navigate(
          `/${organizationCmId}/discretionary/withdrawal/signed?orderId=${data.order.id}`,
          {
            replace: true,
          },
        );
        break;

      case OrderType.Transfer:
        navigate(
          `/${organizationCmId}/discretionary/transfer/signed?orderId=${data.order.id}`,
          {
            replace: true,
          },
        );
        break;

      default:
        throw new Error(
          // @ts-expect-error The order type will be of type `never` when the switch statement
          // handles all possible cases. Unfortunately, we do not have exhaustive switch statement check
          // enabled in our TypeScript. Otherwise, TypeScript would display an error that not all
          // type permutations are handled in the switch statement. That would allow us to skip the `default`
          // switch option. Since it is not enabled, we need to handle the `never` case here.
          `Unknown order type received when returning from signing order. Received order type [${data.order.orderType}].`,
        );
    }
  }, [data]);

  return <Pending center />;
};
