import Alert from '@storeblocks/alert';
import { Form, Formik, FormikHelpers } from 'formik';
import React from 'react';
import { useSearchParams } from 'react-router-dom';
import styled from 'styled-components';

import { useFundListQuery } from '@/api/funds/fundsApi';
import { OrderType } from '@/api/order/models/OrderType';
import { OrderWithSignUrl } from '@/api/order/models/OrderWithSignUrl';
import { usePostOrderMutation } from '@/api/order/orderApi';
import { mapPostFundOrderModelToDto } from '@/api/order/utils/mapPostFundOrderModelToDto';
import { useOrderListQuery } from '@/api/orderList/orderListApi';
import { Permission } from '@/api/permissions/models/Permission';
import { useOrganizationCmId } from '@/api/userSettings/hooks/useOrganizationCmId';
import { useUserSettings } from '@/api/userSettings/utils/useUserSettings';
import { useCurrentUserInfo } from '@/auth/hooks';
import { ContactForm } from '@/components/ContactForm/ContactForm';
import { Column, Row } from '@/components/Grid';
import { useHasPermission } from '@/components/Permissions/useHasPermission';
import { Show } from '@/components/Show';
import { H2 } from '@/components/Typography/H2';
import { WithGap } from '@/components/WithGap';
import { isCypress } from '@/config/utils';
import { useFms } from '@/hooks/useFms';
import { useOrderPageContext } from '@/pages/Order/OrderPageContext';

import { useOrderSteps } from '../../components/OrderStepsProvider';
import { useContactFormSource } from '../../hooks/useContactFormSource';
import { useIsCurrentUserOwner } from '../../hooks/useIsCurrentUserOwner';
import { FundOrder } from '../../types/Order';
import { getEarliestCutOffTime } from '../../utils/getEarliestCutOffTime';
import { CutoffInformationAlert } from '../OrderDetails/components/CutoffInformationAlert';
import { ConfirmInformationIsCorrectCheckbox } from './components/ConfirmInformationIsCorrectCheckbox';
import { NavigationButtons } from './components/NavigationButtons';
import { OrderDetailsTable } from './components/OrderDetailsTable';
import { PostFailedAlert } from './components/PostFailedAlert';
import { SwitchOrderDetailsTables } from './components/SwitchOrderDetailsTables';
import { UnitsInformationAlert } from './components/UnitsInformationAlert';
import { ConfirmFormValues } from './types/ConfirmFormValues';
import { validationSchema } from './utils/validationSchema';

const postError = 'post-error';

const initialFormValues: ConfirmFormValues = {
  confirmInformationIsCorrect: false,
};

export const FundOrderConfirm: React.FC = () => {
  const texts = useFms();
  const [, setSearchParams] = useSearchParams();

  const organizationCmId = useOrganizationCmId();
  const orderListQuery = useOrderListQuery();

  const { data: fundList } = useFundListQuery();

  const isOwner = useIsCurrentUserOwner();
  const userInfo = useCurrentUserInfo();
  const { language } = useUserSettings();
  const hasPermission = useHasPermission();

  const {
    orderType,
    currentOrder,
    orderForSigning,
    signingCancelled,
    hasExistingOrder,
  } = useOrderPageContext<FundOrder>();
  const contactFormSource = useContactFormSource();

  const { completeCurrentStep } = useOrderSteps();

  const [postOrder] = usePostOrderMutation();

  if (!currentOrder) {
    throw new Error('Cannot use Confirm page without currentOrder.');
  }

  // Get the earliest cut-off time for the funds in the order.
  const cutOff = getEarliestCutOffTime(
    currentOrder.orderPlacements
      .map((placement) => placement.isin)
      .map((isin) => fundList.byIsin[isin].cutOffTime),
  );

  const handleSubmit = async (
    _: ConfirmFormValues,
    formikHelpers: FormikHelpers<ConfirmFormValues>,
  ): Promise<void> => {
    try {
      let createdOrder: OrderWithSignUrl;

      if (!orderForSigning) {
        const dto = mapPostFundOrderModelToDto(
          organizationCmId,
          currentOrder,
          language,
        );

        createdOrder = await postOrder({ order: dto, orderType }).unwrap();
      }

      // Set the orderId in the url to allow the user to click the back button to edit their order.
      setSearchParams(
        {
          orderId: orderForSigning
            ? orderForSigning.id.toString()
            : createdOrder.order.id.toString(),
        },
        { replace: true },
      );

      if (isCypress()) {
        // Don't redirect to signing when we are on Cypress tests due to
        // window.location.href redirects the Cypress Chrome instance and not
        // the page under test.

        completeCurrentStep();
        return;
      }

      // Redirect to Signicat for signing if the user has order sign permission
      // and the current user is one of the signatories.
      if (
        hasPermission(Permission.OrdersSign) &&
        currentOrder.signatories.includes(userInfo.userCmId)
      ) {
        window.location.href = orderForSigning
          ? orderForSigning.signUrl
          : createdOrder.signUrl;
        return;
      }

      // Refetch the order list to show the newly created order.
      orderListQuery.refetch();

      // Do not redirect and complete the current step if the user does not have order sign permission
      // or if the current user is not one of the signatories but complete the current step to immediately
      // show the completed step instead.
      completeCurrentStep();
    } catch (error) {
      formikHelpers.setStatus(postError);
    }
  };

  return (
    <Container>
      <WithGap gap="24">
        <Row>
          <Column $span={9}>
            <ContactForm source={contactFormSource} />
          </Column>
        </Row>
        <H2>{texts.orders.form.steps.confirm.title}</H2>
      </WithGap>

      {(currentOrder.orderType === OrderType.Redemption ||
        currentOrder.orderType === OrderType.Subscription) && (
        <>
          <OrderDetailsTable
            portfolioShortName={currentOrder.portfolioId}
            orderPlacements={currentOrder.orderPlacements}
            payments={currentOrder.payments}
          />
        </>
      )}

      {currentOrder.orderType === OrderType.Switch && (
        <SwitchOrderDetailsTables
          portfolioShortName={currentOrder.portfolioId}
          transferToPortfolioNumber={currentOrder.transferToPortfolioNumber}
          orderPlacements={currentOrder.orderPlacements}
        />
      )}

      <UnitsInformationAlert orderType={currentOrder.orderType} />

      <Formik
        initialValues={initialFormValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema(texts)}
      >
        {({ status, isSubmitting }) => (
          <StyledForm>
            <WithGap gap="24">
              {!!cutOff && (
                <CutoffInformationAlert
                  orderType={orderType}
                  cutOffTime={cutOff.cutOffTime}
                  isPastCutoff={cutOff.isPastCutOff}
                />
              )}

              <ConfirmInformationIsCorrectCheckbox orderType={orderType} />

              <Show when={status === postError} animate>
                <PostFailedAlert />
              </Show>
            </WithGap>

            {signingCancelled && (
              <Alert
                data-test="signing-cancelled-alert"
                variant="info"
                title={texts.orders.form.steps.confirm.signingCancelledTitle}
                description={
                  texts.orders.form.steps.confirm.signingCancelledDescription
                }
              />
            )}

            <NavigationButtons
              isPending={isSubmitting}
              canGoBack={isOwner && !hasExistingOrder}
            />
          </StyledForm>
        )}
      </Formik>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 48px;
`;

const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  gap: 48px;
`;
