import { formatISO } from 'date-fns';

import { Language } from '@/i18n/Language';
import { TransferOrder } from '@/pages/Order/types/Order';

import { PostTransferOrderDto } from '../dtos/PostTransferOrderDto';

export const mapPostTransferOrderModelToTransferDto = (
  transferOrder: TransferOrder,
  language: Language,
): PostTransferOrderDto => {
  // The backend only want one currency.
  // We take the currency from the bank account the amount is withdrawn from.
  const currency = transferOrder.fromPortfolioBankAccountCurrency;

  return {
    order: {
      fromPortfolioId: transferOrder.fromPortfolioId,
      fromPortfolioBankAccountNumber:
        transferOrder.fromPortfolioBankAccountNumber,
      toPortfolioId: transferOrder.toPortfolioId,
      toPortfolioBankAccountNumber: transferOrder.toPortfolioBankAccountNumber,
      currency,
      amount: transferOrder.amount,
      expectedTransferDate: formatISO(transferOrder.expectedTransferDate, {
        representation: 'date',
      }),
      message: transferOrder.messageToSam?.trim() || null,
      signatories: transferOrder.signatories,
      signingProviderId: transferOrder.signingProvider,
    },
    language,
  };
};
