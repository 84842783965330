import { FMSTexts } from '@/types/fms';
import { Unit } from '@/types/Unit';

export const getUnitLabel = (unit: Unit, texts: FMSTexts): string => {
  switch (unit) {
    case Unit.Amount:
      return texts.charts.assetReturn.unit.amount;
    case Unit.Percent:
      return texts.charts.assetReturn.unit.percent;
    default:
      throw new Error(`No unit label was found for unit [${unit}]`);
  }
};
