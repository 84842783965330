import { useUserDateFnsLocale } from '@/i18n/hooks/useUserDateFnsLocale';

type TimeFormatWidth = 'short' | 'medium' | 'long';

type TimeFormat = string | undefined;

/**
 * Hook to return the correct time format for the current users locale
 *
 * @example Example for `short` time format:
 * const timeFormat = useUserTimeFormat('short') => `00:00`.
 * @example Example for `medium` time format:
 * const timeFormat = useUserTimeFormat('medium') => `00:00:00`.
 * @example Example for `long` time format:
 * const timeFormat = useUserTimeFormat('long') => `00:00:00 GMT+1`.
 *
 * @param width {TimeFormatWidth} Specifies the time format length to be used. Defaults to short.
 * @returns Time format string
 */
export const useUserTimeFormat = (
  width: TimeFormatWidth = 'short',
): TimeFormat => {
  const language = useUserDateFnsLocale();

  return language.formatLong?.time({ width });
};
