import { FMSTexts } from '@/types/fms';

import { AssetReturnPeriod } from '../types';

export const getAssetReturnPeriodLabel = (
  period: AssetReturnPeriod,
  texts: FMSTexts,
): string => {
  switch (period) {
    case AssetReturnPeriod.YTD:
      return texts.charts.assetReturn.period.ytd;
    case AssetReturnPeriod.QTD:
      return texts.charts.assetReturn.period.qtd;
    case AssetReturnPeriod.MTD:
      return texts.charts.assetReturn.period.mtd;
    default:
      throw new Error(`Invalid asset return period [${period}]`);
  }
};
