import { useUserDateFnsLocale } from '@/i18n/hooks/useUserDateFnsLocale';
import { useUserDateFormat } from '@/i18n/hooks/useUserDateFormat';
import { useUserTimeFormat } from '@/i18n/hooks/useUserTimeFormat';

type DateTimeFormatWidth = 'short' | 'medium' | 'long';

type DateTimeFormat = string | undefined;

/**
 * Hook to return the correct datetime format for the current users locale
 *
 * @example Example for `short` datetime format:
 * const dateTimeFormat = useUserDateTimeFormat('short') => `23.02.22 00:00`.
 * @example Example for `medium` datetime format:
 * const dateTimeFormat = useUserDateTimeFormat('medium') => `23. feb. 2022 00:00:00`.
 * @example Example for `long` datetime format:
 * const dateTimeFormat = useUserDateTimeFormat('long') => `23. februar 2022 kl. 00:00:00 GMT+1`.
 *
 * @param width {DateTimeFormatWidth} Specifies the datetime format length to be used. Defaults to short.
 * @returns Datetime format string
 */
export const useUserDateTimeFormat = (
  width: DateTimeFormatWidth = 'short',
): DateTimeFormat => {
  const language = useUserDateFnsLocale();

  const dateFormat = useUserDateFormat(width);
  const timeFormat = useUserTimeFormat(width);

  const dateTimeFormat = language.formatLong?.dateTime({ width });

  return dateTimeFormat
    .replace('{{date}}', dateFormat)
    .replace('{{time}}', timeFormat);
};
