import { Form, Formik } from 'formik';
import React from 'react';

import { OrderType } from '@/api/order/models/OrderType';
import { useCurrentUserInfo } from '@/auth/hooks';
import { useHasPermission } from '@/components/Permissions/useHasPermission';
import { useOrderSteps } from '@/pages/Order/components/OrderStepsProvider';
import { useOrderPageContext } from '@/pages/Order/OrderPageContext';
import { DepositOrder } from '@/pages/Order/types/Order';

import { useDiscretionaryPortfolios } from '../../hooks/useDiscretionaryPortfolios';
import { DepositOrderFormValues } from '../../types/DepositOrderFormValues';
import { getDefaultSignatoryOption } from '../../utils/getDefaultSignatoryOption';
import { getSignatoryOptionFromSignatories } from '../../utils/getSignatoryOptionFromSignatories';
import { useCreateDepositValidationSchema } from '../../validationSchemas/hooks/useCreateDepositValidationSchema';
import { DepositOrderFormContent } from './DepositOrderFormContent';

export const DepositOrderForm: React.FC = () => {
  const hasPermission = useHasPermission();

  const { userType, userCmId } = useCurrentUserInfo();
  const { completeCurrentStep } = useOrderSteps();
  const { setCurrentOrder, currentOrder } = useOrderPageContext<DepositOrder>();

  const validationSchema = useCreateDepositValidationSchema({
    userType,
  });

  const portfolios = useDiscretionaryPortfolios();

  if (portfolios.length === 0) {
    throw new Error(
      'Customer does not have any discretionary portfolios. One or more discretionary portfolios is required to use the deposit order page.',
    );
  }

  let initialPortfolioId = '';
  let initialBankAccountNumber = '';
  let initialCurrency = undefined;

  //?: Does the customer only have one portfolio?
  if (portfolios.length === 1) {
    // -> Yes, the customer has only one portfolio.

    // This means we can preselect the portfolio since
    // there is only one to choose from.
    initialPortfolioId = portfolios[0].id;

    const portfolioBankAccounts = portfolios[0].portfolioBankAccounts;

    // See if we can also preselect the portfolio bank account
    // if there is only one bank account to choose from.
    initialBankAccountNumber =
      portfolioBankAccounts.length === 1
        ? portfolioBankAccounts[0].accountNumber
        : '';

    // If there is only one portfolio bank account we can
    // then also set the currency.
    initialCurrency =
      portfolioBankAccounts.length === 1
        ? portfolioBankAccounts[0].currency
        : undefined;
  }

  let initialValues: DepositOrderFormValues;
  if (currentOrder) {
    // The user has clicked the back button on the order summary page.
    // Populate the form with the previous values.
    initialValues = {
      portfolioId: currentOrder.portfolioId,
      toPortfolioBankAccount: currentOrder.toPortfolioBankAccountNumber,
      currency: currentOrder.currency,
      amount: currentOrder.amount,
      expectedDepositDate: currentOrder.expectedDepositDate,
      messageToSam: currentOrder.messageToSam,
      signer: currentOrder.signatories[0] ?? null,
      cosigner: currentOrder.signatories[1],
      signatoryOption: getSignatoryOptionFromSignatories(
        currentOrder.signatories,
        userCmId,
        hasPermission,
      ),
      signingProvider: currentOrder.signingProvider,
    };
  } else {
    // This is a fresh new order.
    // Initialize the order to its initial state.
    initialValues = {
      portfolioId: initialPortfolioId,
      toPortfolioBankAccount: initialBankAccountNumber,
      currency: initialCurrency,
      amount: 0,
      expectedDepositDate: undefined,
      messageToSam: '',
      signer: null,
      cosigner: undefined,
      signatoryOption: getDefaultSignatoryOption(hasPermission),
      signingProvider: undefined,
    };
  }

  const handleSubmit = (values: DepositOrderFormValues): void => {
    const order: DepositOrder = {
      orderType: OrderType.Deposit,
      userId: userCmId,
      portfolioId: values.portfolioId,
      toPortfolioBankAccountNumber: values.toPortfolioBankAccount,
      amount: values.amount,
      currency: values.currency,
      expectedDepositDate: values.expectedDepositDate,
      messageToSam: values.messageToSam,
      signatories: [values.signer, values.cosigner].filter(Boolean),
      signingProvider: values.signingProvider,
    };

    setCurrentOrder(order);
    completeCurrentStep();
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      <Form data-test="deposit-order-form">
        <DepositOrderFormContent />
      </Form>
    </Formik>
  );
};
