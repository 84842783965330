import React from 'react';
import { Outlet } from 'react-router';

import { OrderType } from '@/api/order/models/OrderType';
import { Permission } from '@/api/permissions/models/Permission';
import { getPermissionsFromStore } from '@/api/permissions/utils/getPermissionsFromStore';
import { AnnouncementWarning } from '@/components/AnnouncementWarning';
import { CenteredPageContent } from '@/components/CenteredPageContent';
import { ContactFormPage } from '@/components/ContactForm/ContactFormPage';
import { DevelopmentTools } from '@/components/DevelopmentTools/DevelopmentTools';
import { Disclaimers } from '@/components/Disclaimers';
import { Footer } from '@/components/Footer/Footer';
import { Navbar } from '@/components/Navbar';
import { NeedToUpdateUserSettings } from '@/components/NeedToUpdateUserSettings';
import { NoAccessExternalUser } from '@/components/NoAccessExternalUser';
import { OneOrganizationNavigation } from '@/components/OneOrganizationNavigation';
import { OrganizationNameBanner } from '@/components/OrganizationNameBanner';
import { EsgPage, EsgTaxonomyPage } from '@/pages';
import { AdminPage } from '@/pages/Admin/AdminPage';
import { DocumentsArchivePage } from '@/pages/Archives/DocumentsArchive/DocumentsArchivePage';
import { ContactUsPage } from '@/pages/ContactUs';
import { ContractNotesPage } from '@/pages/ContractNotes';
import { DashboardPage } from '@/pages/Dashboard/DashboardPage';
import { DebugPage } from '@/pages/Debug/DebugPage';
import { DemoModePage } from '@/pages/demoMode/DemoModePage';
import { EsgFundPage } from '@/pages/EsgFund/EsgFundPage';
import { FundListPage } from '@/pages/FundList/FundListPage';
import { HomePage } from '@/pages/Home';
import { NewsPage } from '@/pages/News';
import { InvitationalLinkErrorPage } from '@/pages/NewUser/InvitationalLinkErrorPage';
import { InvitationalLinkLandingPage } from '@/pages/NewUser/InvitationalLinkLandingPage';
import { InvitationalLinkSuccessPage } from '@/pages/NewUser/InvitationalLinkSuccessPage';
import { NotFoundPage } from '@/pages/NotFound';
import {
  FundOrderSignedPage,
  IntermediateFundOrderPage,
  OrderPage,
} from '@/pages/Order';
import { DiscretionaryOrderPage } from '@/pages/Order/DiscretionaryOrderPage';
import { DiscretionaryOrderSignedPage } from '@/pages/Order/DiscretionaryOrderSignedPage';
import { EssSigningCompletedPage } from '@/pages/Order/EssSigningCompletedPage';
import { IntermediateDiscretionaryOrderPage } from '@/pages/Order/IntermediateDiscretionaryOrderPage';
import { NotEligibleForTradePage } from '@/pages/Order/NotEligibleForTradePage';
import { SigningCancelledPage } from '@/pages/Order/SigningCancelledPage';
import { OrderListPage } from '@/pages/OrderList/OrderListPage';
import { PerformanceDashboardPage } from '@/pages/PerformanceDashboard';
import { WaitForPortfolioQuery } from '@/pages/PerformanceDashboard/components/WaitForPortfolioQuery';
import { IntermediateReportsPage, PortfolioReportsPage } from '@/pages/Reports';
import { FundReportsPage } from '@/pages/Reports/FundReportsPage';
import { ForceUpdateInformationPage } from '@/pages/Settings/ForceUpdateInformationPage';
import { UpdateInformationPage } from '@/pages/Settings/UpdateInformationPage';
import { SigningRightCancelledSuccessfullyPage } from '@/pages/SigningRight/SigningRightCancelledSuccessfullyPage';
import { SigningRightCompletedPage } from '@/pages/SigningRight/SigningRightCompletedPage';
import { SigningRightExpiredPage } from '@/pages/SigningRight/SigningRightExpiredPage';
import { SigningRightInProgressPage } from '@/pages/SigningRight/SigningRightInProgressPage';
import { SigningRightPage } from '@/pages/SigningRight/SigningRightPage';
import { SigningRightSignedPage } from '@/pages/SigningRight/SigningRightSignedPage';
import { SigningRightUnconfirmedPage } from '@/pages/SigningRight/SigningRightUnconfirmedPage';
import { EngagementPage } from '@/pages/Sustainability/EngagementPage';
import { IntermediateSustainabilityPage } from '@/pages/Sustainability/IntermediateSustainabilityPage';
import { VotingPage } from '@/pages/Sustainability/VotingPage';
import { TransactionsPage } from '@/pages/Transactions/TransactionsPage';
import { hasPermission } from '@/util/hasPermission';

import { presentationPageRoute } from './presentationPageRoute';
import { ConnectRoute } from './types/ConnectRoute';

export const NotFoundPageId = 'not-found-page';

/**
 * The URL slug for the page that the user is redirected to when they need to
 * update their user settings.
 */
export const ForceUpdateInformationSlug = 'update-information';

export const connectRoutes = (): Array<ConnectRoute> => {
  // Only render routes that the user has permission to navigate to.
  // This will make the user get the 404 page if they try to navigate to a
  // route they don't have permission to.
  return filterRoutesByPermission(allRoutes());
};

const allRoutes = (): Array<ConnectRoute> => [
  presentationPageRoute,
  {
    /**
     * Note.
     * This route is not used in the routing. It is only here to make sure
     * that the log event can find the route to get the URL for its DTO.
     *
     * New users are manually redirected to this route from a conditional
     * check in <IsOnboardingNewUser />.
     */
    id: 'new-user-page',
    path: '/newuser/:uuid',
    element: (
      <>
        <Navbar onlyLogout />
        <Outlet />
      </>
    ),
    children: [
      {
        index: true,
        element: <InvitationalLinkLandingPage />,
      },
      {
        id: 'new-user-success-page',
        element: <InvitationalLinkSuccessPage />,
        path: '/newuser/:uuid/success',
      },
      {
        id: 'new-user-error-page',
        element: <InvitationalLinkErrorPage />,
        path: '/newuser/:uuid/error',
      },
    ],
  },
  {
    id: 'force-update-information-page',
    // Note: This route is not in the child of the organization route
    // because we want to have a custom layout for this page.
    path: `/:organizationCmId/${ForceUpdateInformationSlug}`,
    permissions: [Permission.ConnectRead],
    element: (
      <>
        <Navbar onlyLogout />
        <ForceUpdateInformationPage />
      </>
    ),
  },
  {
    id: 'home-page',
    element: (
      <OneOrganizationNavigation>
        <NeedToUpdateUserSettings>
          <Navbar />
          <Outlet />

          <CenteredPageContent>
            <Disclaimers />
          </CenteredPageContent>

          <Footer />

          {window.config.enableDevTools && <DevelopmentTools />}
        </NeedToUpdateUserSettings>
      </OneOrganizationNavigation>
    ),
    path: '/',
    children: [
      {
        element: <HomePage />,
        index: true,
      },
      {
        id: 'news-page',
        element: <NewsPage />,
        path: '/news',
      },
      {
        id: 'contact-us-page',
        element: <ContactUsPage />,
        path: '/contact-us/:tabId',
      },
      {
        id: 'demo-mode-start-page',
        element: <DemoModePage />,
        path: '/demo',
      },
      window.config.enableDevTools && {
        id: 'debug-page',
        element: <DebugPage />,
        path: '/debug',
        permissions: [Permission.ConnectRead],
      },
      {
        path: '/:organizationCmId',
        id: 'dashboard-page',
        element: <Outlet />,
        permissions: [Permission.ConnectRead],
        children: [
          {
            element: <DashboardPage />,
            index: true,
            permissions: [Permission.ConnectRead],
          },
          {
            id: 'update-information-page',
            path: '/:organizationCmId/user-settings',
            permissions: [Permission.ConnectRead],
            element: (
              <>
                <OrganizationNameBanner />
                <UpdateInformationPage />
              </>
            ),
          },
          window.config.enableDevTools && {
            id: 'debug-page',
            element: (
              <>
                <OrganizationNameBanner />
                <DebugPage />
              </>
            ),
            path: '/:organizationCmId/debug',
          },
          {
            id: 'contact-form-page',
            path: '/:organizationCmId/contact-us',
            permissions: [Permission.ConnectRead],
            element: (
              <>
                <OrganizationNameBanner />
                <ContactFormPage />
              </>
            ),
          },
          {
            id: 'performance-dashboard',
            path: '/:organizationCmId/performance',
            children: [
              {
                index: true,
                element: (
                  <>
                    <OrganizationNameBanner />
                    <WaitForPortfolioQuery>
                      <PerformanceDashboardPage />
                    </WaitForPortfolioQuery>
                  </>
                ),
                permissions: [Permission.ConnectRead],
              },
            ],
          },
          {
            id: 'reports-page',
            path: '/:organizationCmId/reports',
            element: (
              <>
                <OrganizationNameBanner />
                <Outlet />
              </>
            ),
            children: [
              {
                index: true,
                element: <IntermediateReportsPage />,
              },
              {
                id: 'reports-portfolio-page',
                path: '/:organizationCmId/reports/portfolio',
                permissions: [Permission.ReportsRead],
                element: <PortfolioReportsPage />,
              },
              {
                id: 'reports-funds-page',
                element: <FundReportsPage />,
                path: '/:organizationCmId/reports/funds',
                permissions: [Permission.ReportsRead],
              },
              {
                id: 'reports-contract-notes',
                element: <ContractNotesPage />,
                path: '/:organizationCmId/reports/contract-notes',
                permissions: [Permission.ConnectRead, Permission.ReportsRead],
              },
            ],
          },
          {
            id: 'sustainability-page',
            path: '/:organizationCmId/sustainability',
            element: (
              <>
                <OrganizationNameBanner />
                <Outlet />
              </>
            ),
            children: [
              { index: true, element: <IntermediateSustainabilityPage /> },
              {
                id: 'sustainability-esg-page',
                path: '/:organizationCmId/sustainability/esg',
                permissions: [Permission.ConnectRead],
                children: [
                  {
                    index: true,
                    element: <EsgPage />,
                    permissions: [Permission.ConnectRead],
                  },
                  {
                    id: 'sustainability-esg-taxonomy-page',
                    element: <EsgTaxonomyPage />,
                    isBeta: true,
                    path: '/:organizationCmId/sustainability/esg/taxonomy',
                    permissions: [Permission.ConnectRead],
                  },
                  {
                    id: 'sustainability-esg-fund-page',
                    element: <EsgFundPage />,
                    path: '/:organizationCmId/sustainability/esg/fund/:isin',
                    permissions: [Permission.ConnectRead],
                  },
                  {
                    id: 'sustainability-esg-fund-page',
                    element: <EsgFundPage />,
                    path: '/:organizationCmId/sustainability/esg/fund/:isin/:fundName',
                    permissions: [Permission.ConnectRead],
                  },
                  {
                    id: 'sustainability-esg-page',
                    element: <EsgPage />,
                    path: '/:organizationCmId/sustainability/esg/:portfolioGroupId',
                    permissions: [Permission.ConnectRead],
                  },
                ],
              },
              {
                id: 'sustainability-engagement-page',
                path: '/:organizationCmId/sustainability/engagement',
                permissions: [Permission.ConnectRead],
                element: <EngagementPage />,
              },
              {
                id: 'sustainability-voting-page',
                path: '/:organizationCmId/sustainability/voting',
                permissions: [Permission.ConnectRead],
                element: <VotingPage />,
              },
            ],
          },
          {
            id: 'trading-not-eligible-page',
            path: '/:organizationCmId/trading/not-eligible',
            permissions: [Permission.OrdersWriteNotEligible],
            element: (
              <>
                <OrganizationNameBanner />
                <CenteredPageContent>
                  <NotEligibleForTradePage />
                </CenteredPageContent>
              </>
            ),
          },
          {
            id: 'discretionary-order-page',
            path: '/:organizationCmId/discretionary',
            permissions: [Permission.OrdersWrite],
            element: (
              <>
                <OrganizationNameBanner />
                <CenteredPageContent>
                  <AnnouncementWarning />
                  <Outlet />
                </CenteredPageContent>
              </>
            ),
            children: [
              {
                index: true,
                element: <IntermediateDiscretionaryOrderPage />,
              },
              {
                id: 'deposit-page',
                path: '/:organizationCmId/discretionary/deposit',
                permissions: [Permission.OrdersWrite],
                element: (
                  <DiscretionaryOrderPage
                    key="discretionary-order-deposit"
                    orderType={OrderType.Deposit}
                  />
                ),
              },
              {
                id: 'deposit-signed-page',
                path: '/:organizationCmId/discretionary/deposit/signed',
                permissions: [Permission.OrdersWrite],
                element: (
                  <DiscretionaryOrderSignedPage orderType={OrderType.Deposit} />
                ),
              },
              {
                id: 'withdrawal-page',
                path: '/:organizationCmId/discretionary/withdrawal',
                permissions: [Permission.OrdersWrite],
                element: (
                  <DiscretionaryOrderPage
                    key="discretionary-order-withdrawal"
                    orderType={OrderType.Withdrawal}
                  />
                ),
              },
              {
                id: 'withdrawal-signed-page',
                path: '/:organizationCmId/discretionary/withdrawal/signed',
                permissions: [Permission.OrdersWrite],
                element: (
                  <DiscretionaryOrderSignedPage
                    orderType={OrderType.Withdrawal}
                  />
                ),
              },
              {
                id: 'transfer-page',
                path: '/:organizationCmId/discretionary/transfer',
                permissions: [Permission.OrdersWrite],
                element: (
                  <DiscretionaryOrderPage
                    key="discretionary-order-transfer"
                    orderType={OrderType.Transfer}
                  />
                ),
              },
              {
                id: 'transfer-signed-page',
                path: '/:organizationCmId/discretionary/transfer/signed',
                permissions: [Permission.OrdersWrite],
                element: (
                  <DiscretionaryOrderSignedPage
                    orderType={OrderType.Transfer}
                  />
                ),
              },
            ],
          },
          {
            id: 'trading-page',
            path: '/:organizationCmId/trading',
            permissions: [Permission.OrdersRead],
            element: (
              <>
                <OrganizationNameBanner />
                <CenteredPageContent>
                  <AnnouncementWarning />
                  <Outlet />
                </CenteredPageContent>
              </>
            ),
            children: [
              {
                index: true,
                element: <IntermediateFundOrderPage />,
              },
              {
                id: 'trading-subscription-page',
                path: '/:organizationCmId/trading/subscription',
                permissions: [Permission.OrdersRead],
                element: (
                  <OrderPage
                    key="fund-order-subscription"
                    orderType={OrderType.Subscription}
                  />
                ),
              },
              {
                id: 'trading-subscription-signed-page',
                path: '/:organizationCmId/trading/subscription/signed',
                permissions: [Permission.OrdersRead],
                element: (
                  <FundOrderSignedPage orderType={OrderType.Subscription} />
                ),
              },
              {
                id: 'trading-redemption-page',
                path: '/:organizationCmId/trading/redemption',
                permissions: [Permission.OrdersRead],
                element: (
                  <OrderPage
                    key="fund-order-redemption"
                    orderType={OrderType.Redemption}
                  />
                ),
              },
              {
                id: 'trading-redemption-signed-page',
                path: '/:organizationCmId/trading/redemption/signed',
                permissions: [Permission.OrdersRead],
                element: (
                  <FundOrderSignedPage orderType={OrderType.Redemption} />
                ),
              },
              {
                id: 'trading-switch-page',
                path: '/:organizationCmId/trading/switch',
                permissions: [Permission.OrdersRead],
                element: (
                  <OrderPage
                    key="fund-order-switch"
                    orderType={OrderType.Switch}
                  />
                ),
              },
              {
                id: 'trading-switch-signed-page',
                path: '/:organizationCmId/trading/switch/signed',
                permissions: [Permission.OrdersRead],
                element: <FundOrderSignedPage orderType={OrderType.Switch} />,
              },
              {
                id: 'trading-order-list-page',
                path: '/:organizationCmId/trading/orders',
                permissions: [Permission.OrdersRead],
                element: <OrderListPage />,
              },
              {
                id: 'trading-signed-page',
                path: '/:organizationCmId/trading/signed',
                permissions: [Permission.OrdersRead],
                element: <EssSigningCompletedPage />,
              },
              {
                id: 'trading-signing-cancelled-page',
                path: '/:organizationCmId/trading/signing-cancelled',
                permissions: [Permission.OrdersRead],
                element: <SigningCancelledPage />,
              },
              {
                id: 'signing-right-trading-page',
                path: '/:organizationCmId/trading/signing-right',
                element: <SigningRightPage />,
                permissions: [Permission.OrdersSignMissingSigningRight],
              },
              {
                id: 'signing-right-trading-page-aborted',
                path: '/:organizationCmId/trading/signing-right/cancelled',
                element: <SigningRightInProgressPage />,
                permissions: [Permission.OrdersSignMissingSigningRight],
              },
              {
                id: 'signing-right-trading-page-in-progress',
                path: '/:organizationCmId/trading/signing-right/in-progress',
                element: <SigningRightInProgressPage />,
                permissions: [Permission.OrdersSignMissingSigningRight],
              },
              {
                id: 'signing-right-trading-page-signed',
                path: '/:organizationCmId/trading/signing-right/signed',
                element: <SigningRightSignedPage />,
                permissions: [Permission.OrdersSignMissingSigningRight],
              },
              {
                id: 'signing-right-trading-page-unconfirmed',
                path: '/:organizationCmId/trading/signing-right/unconfirmed',
                element: <SigningRightUnconfirmedPage />,
                permissions: [Permission.OrdersRead],
              },
              {
                id: 'signing-right-trading-page-success',
                path: '/:organizationCmId/trading/signing-right/completed',
                element: <SigningRightCompletedPage />,
                permissions: [Permission.OrdersRead],
              },
              {
                id: 'signing-right-trading-page-in-cancelled-successfully',
                path: '/:organizationCmId/trading/signing-right/cancelled-successfully',
                element: <SigningRightCancelledSuccessfullyPage />,
                permissions: [Permission.OrdersRead],
              },
              {
                id: 'signing-right-trading-page-expired',
                path: '/:organizationCmId/trading/signing-right/expired',
                element: <SigningRightExpiredPage />,
                permissions: [Permission.OrdersRead],
              },
              {
                id: 'trading-transactions-page',
                path: '/:organizationCmId/trading/transactions',
                element: <TransactionsPage />,
                permissions: [Permission.OrdersRead],
              },
            ],
          },
          {
            id: 'documents-page',
            path: '/:organizationCmId/documents',
            permissions: [
              Permission.ConnectRead,
              Permission.DocumentStorageRead,
            ],
            element: (
              <>
                <OrganizationNameBanner />
                <DocumentsArchivePage />
              </>
            ),
          },
          {
            id: 'fund-list-page',
            element: (
              <>
                <OrganizationNameBanner />
                <FundListPage />
              </>
            ),
            path: '/:organizationCmId/fund-list',
            permissions: [Permission.ConnectRead],
          },
          {
            id: 'admin-page',
            element: (
              <>
                <OrganizationNameBanner />
                <AdminPage />
              </>
            ),
            path: '/:organizationCmId/admin/:tabId',
            permissions: [Permission.ConnectRead, Permission.UserAdminRead],
          },
          {
            id: 'news-page',
            element: (
              <>
                <OrganizationNameBanner />
                <NewsPage />
              </>
            ),
            path: '/:organizationCmId/news',
            permissions: [Permission.ConnectRead],
          },
          {
            id: 'contact-us-page',
            element: (
              <>
                <OrganizationNameBanner />
                <ContactUsPage />
              </>
            ),
            path: '/:organizationCmId/contact-us/:tabId',
            permissions: [Permission.ConnectRead],
          },
          {
            id: NotFoundPageId,
            element: (
              <>
                <OrganizationNameBanner />
                <NotFoundPage />
              </>
            ),
            path: '/:organizationCmId/*',
          },
        ].filter(Boolean),
      },
    ].filter(Boolean),
  },
  {
    id: 'no-access-external-users-page',
    element: <NoAccessExternalUser />,
    path: '/no-access-external-users',
  },
  {
    id: NotFoundPageId,
    element: <NotFoundPage />,
    path: '*',
  },
];

function filterRoutesByPermission(routes: ConnectRoute[]): ConnectRoute[] {
  const userPermissions = getPermissionsFromStore();

  return routes.reduce((filtered, route) => {
    if (
      !route.permissions ||
      hasPermission(
        { mustHave: 'all', permissions: route.permissions },
        userPermissions,
      )
    ) {
      const filteredItem = { ...route };
      if (route.children) {
        filteredItem.children = filterRoutesByPermission(route.children);
      }
      filtered.push(filteredItem);
    }

    return filtered;
  }, [] as ConnectRoute[]);
}
