import { formatISO } from 'date-fns';

import { Language } from '@/i18n/Language';
import { DepositOrder } from '@/pages/Order/types/Order';

import { PostDepositOrderDto } from '../dtos/PostDepositOrderDto';

export const mapPostDepositOrderModelToDepositDto = (
  depositOrder: DepositOrder,
  language: Language,
): PostDepositOrderDto => {
  return {
    order: {
      portfolioId: depositOrder.portfolioId,
      toPortfolioBankAccountNumber: depositOrder.toPortfolioBankAccountNumber,
      amount: depositOrder.amount,
      currency: depositOrder.currency,
      expectedDepositDate: formatISO(depositOrder.expectedDepositDate, {
        representation: 'date',
      }),
      message: depositOrder.messageToSam?.trim() || null,
      signingProviderId: depositOrder.signingProvider,
      signatories: depositOrder.signatories,
    },
    language,
  };
};
