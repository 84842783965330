import React from 'react';

import { isDiscretionaryType, isFundType } from '@/api/order/models/OrderType';

import { DiscretionaryOrderCompleted } from '../../components/DiscretionaryOrderCompleted';
import { FundOrderCompleted } from '../../components/FundOrderCompleted';
import { useOrderSteps } from '../../components/OrderStepsProvider';
import { useOrderPageContext } from '../../OrderPageContext';

interface Props {
  isOwner: boolean;
  hasCosigners: boolean;
}

export const Completed: React.FC<Props> = ({ isOwner, hasCosigners }) => {
  const { setActiveStep } = useOrderSteps();
  const { reset } = useOrderPageContext();
  const { orderType } = useOrderPageContext();

  const handleNewOrderClick = (): void => {
    reset();
    setActiveStep(0);
  };

  if (isFundType(orderType)) {
    return (
      <FundOrderCompleted
        isOwner={isOwner}
        hasCosigners={hasCosigners}
        newOrderOnClick={handleNewOrderClick}
      />
    );
  }

  if (isDiscretionaryType(orderType)) {
    return (
      <DiscretionaryOrderCompleted
        isOwner={isOwner}
        hasCosigners={hasCosigners}
        newOrderOnClick={handleNewOrderClick}
      />
    );
  }

  throw new Error(
    `Order completed page not implemented for order type [${orderType}].`,
  );
};
