import React, { useEffect } from 'react';

import {
  isDiscretionaryType,
  isFundType,
  OrderType,
} from '@/api/order/models/OrderType';
import { useConfirmOrderSignedMutation } from '@/api/order/orderApi';

import { DiscretionaryOrderCompleted } from '../../components/DiscretionaryOrderCompleted';
import { FundOrderCompleted } from '../../components/FundOrderCompleted';
import { useLogOrderSigned } from '../../utils/useLogOrderSigned';

interface Props {
  orderId: string;
  isOwner: boolean;
  hasCosigners: boolean;
  orderType: OrderType;
}

export const Signed: React.FC<Props> = ({
  orderId,
  isOwner,
  hasCosigners,
  orderType,
}) => {
  useLogOrderSigned({
    orderId,
    hasCosigners,
    signedBy: isOwner ? 'owner' : 'cosigner',
  });

  const [confirmOrderSigned] = useConfirmOrderSignedMutation();

  useEffect(() => {
    confirmOrderSigned({ orderId });
  }, [orderId]);

  if (isFundType(orderType)) {
    return <FundOrderCompleted isOwner={isOwner} hasCosigners={hasCosigners} />;
  }

  if (isDiscretionaryType(orderType)) {
    return (
      <DiscretionaryOrderCompleted
        isOwner={isOwner}
        hasCosigners={hasCosigners}
      />
    );
  }

  throw new Error(
    `Order signed page not implemented for order type [${orderType}].`,
  );
};
