import { Form, Formik } from 'formik';
import React, { FC } from 'react';

import { OrderType } from '@/api/order/models/OrderType';
import { useCurrentUserInfo } from '@/auth/hooks';
import { useHasPermission } from '@/components/Permissions/useHasPermission';
import { useOrderSteps } from '@/pages/Order/components/OrderStepsProvider';
import { useOrderPageContext } from '@/pages/Order/OrderPageContext';
import { TransferOrder } from '@/pages/Order/types/Order';

import { useDiscretionaryPortfolios } from '../../hooks/useDiscretionaryPortfolios';
import { TransferOrderFormValues } from '../../types/TransferOrderFormValues';
import { getDefaultSignatoryOption } from '../../utils/getDefaultSignatoryOption';
import { getSignatoryOptionFromSignatories } from '../../utils/getSignatoryOptionFromSignatories';
import { useCreateTransferValidationSchema } from '../../validationSchemas/hooks/useCreateTransferValidationSchema';
import { TransferOrderFormContent } from './TransferOrderFormContent';

export const TransferOrderForm: FC = () => {
  const hasPermission = useHasPermission();

  const { userCmId, userType } = useCurrentUserInfo();
  const { completeCurrentStep } = useOrderSteps();
  const { setCurrentOrder, currentOrder } =
    useOrderPageContext<TransferOrder>();

  const portfolios = useDiscretionaryPortfolios();

  if (portfolios.length === 0) {
    throw new Error(
      'Customer does not have any discretionary portfolios. One or more discretionary portfolios is required to use the transfer order page.',
    );
  }

  const validationSchema = useCreateTransferValidationSchema({ userType });

  let initialValues: TransferOrderFormValues;

  if (currentOrder) {
    initialValues = {
      toPortfolioId: currentOrder.toPortfolioId,
      toPortfolioBankAccountNumber: currentOrder.toPortfolioBankAccountNumber,
      toCurrency: currentOrder.toPortfolioBankAccountCurrency,
      fromPortfolioId: currentOrder.fromPortfolioId,
      fromPortfolioBankAccountNumber:
        currentOrder.fromPortfolioBankAccountNumber,
      fromCurrency: currentOrder.fromPortfolioBankAccountCurrency,
      amount: currentOrder.amount,
      messageToSam: currentOrder.messageToSam,
      expectedTransferDate: currentOrder.expectedTransferDate,
      signer: currentOrder.signatories[0] ?? null,
      cosigner: currentOrder.signatories[1],
      signatoryOption: getSignatoryOptionFromSignatories(
        currentOrder.signatories,
        userCmId,
        hasPermission,
      ),
      signingProvider: currentOrder.signingProvider,
    };
  } else {
    initialValues = {
      toPortfolioId: '',
      toPortfolioBankAccountNumber: '',
      toCurrency: undefined,
      fromPortfolioId: '',
      fromPortfolioBankAccountNumber: '',
      fromCurrency: undefined,
      amount: 0,
      messageToSam: '',
      expectedTransferDate: undefined,
      signer: null,
      cosigner: undefined,
      signatoryOption: getDefaultSignatoryOption(hasPermission),
      signingProvider: undefined,
    };
  }

  const handleSubmit = (values: TransferOrderFormValues): void => {
    const order: TransferOrder = {
      orderType: OrderType.Transfer,
      userId: userCmId,
      toPortfolioId: values.toPortfolioId,
      toPortfolioBankAccountNumber: values.toPortfolioBankAccountNumber,
      toPortfolioBankAccountCurrency: values.toCurrency,
      fromPortfolioId: values.fromPortfolioId,
      fromPortfolioBankAccountNumber: values.fromPortfolioBankAccountNumber,
      fromPortfolioBankAccountCurrency: values.fromCurrency,
      amount: values.amount,
      messageToSam: values.messageToSam,
      expectedTransferDate: values.expectedTransferDate,
      signatories: [values.signer, values.cosigner].filter(Boolean),
      signingProvider: values.signingProvider,
    };

    setCurrentOrder(order);
    completeCurrentStep();
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      <Form data-test="transfer-order-form">
        <TransferOrderFormContent />
      </Form>
    </Formik>
  );
};
